import AddressResourceDTO from '@/src/services/types/dto/resource/AddressResourceDTO';
import UserAccountDTO from '@/src/services/types/dto/resource/UserAccountDTO';
import AddressModel from '@/src/services/types/model/AddressModel';
import ArticleModel from '@/src/services/types/model/ArticleModel';
import BasketItemModel from '@/src/services/types/model/BasketItemModel';
import BasketModel from '@/src/services/types/model/BasketModel';
import OrderModel from '@/src/services/types/model/OrderModel';
import UserAccountModelModel from '@/src/services/types/model/UserAccountModel';
import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import Vue from 'vue';

export default ({
  basketStorageKey: 'shoppingBasket',
  setLastOrderNumber(orderNumber: string | number) {
    Vue.$sessionStorage.set('lastOrderNumber', orderNumber);
  },
  getLastOrderNumber() {
    return Vue.$sessionStorage.get('lastOrderNumber');
  },
  getArticleInfo(articleId: string | number) {
    const shoppingBasket = this.getLocalBasket();
    const index = shoppingBasket.items.findIndex((basketItem: any) => basketItem.article?.id === articleId);
    return {
      index,
      shoppingBasket,
      article: shoppingBasket.items[index],
    };
  },
  getCount(article: ArticleModel) {
    const articleInfo = this.getArticleInfo(article.id);
    return articleInfo.article?.count ?? 0;
  },
  setCount(article: ArticleModel, count: number) {
    const { index, shoppingBasket } = this.getArticleInfo(article.id);
    if (index >= 0) {
      shoppingBasket.items[index].count = count;
      if (shoppingBasket.items[index].count! < 1) {
        // Zero articles, remove from basket
        shoppingBasket.items.splice(index, 1);
      }
    } else if (count > 0) {
      const basketItem = new BasketItemModel({} as any);
      basketItem.count = count;
      basketItem.article = article;
      shoppingBasket.items.push(basketItem);
    }
    this.setLocalBasket(shoppingBasket);
  },
  add(article: ArticleModel) {
    const count = this.getCount(article);
    this.setCount(article, count + 1);
  },
  remove(article: ArticleModel) {
    const count = this.getCount(article);
    this.setCount(article, count - 1);
  },
  clear() {
    Vue.$sessionStorage.set(this.basketStorageKey, { items: [] });
  },
  delete(article: ArticleModel) {
    const { index, shoppingBasket } = this.getArticleInfo(article.id);
    if (index >= 0) {
      shoppingBasket.items.splice(index, 1);
    } else {
      return false;
    }
    this.setLocalBasket(shoppingBasket);
    return true;
  },
  setLocalBasket(basket: BasketModel) {
    this.setStorage(this.basketStorageKey, basket);
  },
  getLocalBasket() {
    const shoppingBasketDTO = Vue.$sessionStorage.get(this.basketStorageKey) as any;
    const shoppingBasket = new BasketModel(shoppingBasketDTO ?? {} as any);
    if (!Array.isArray(shoppingBasket.items)) {
      shoppingBasket.items = [];
    }
    return shoppingBasket;
  },
  async updateBasketFromServer(setLocalStorage: boolean = true) {
    const basket = this.getLocalBasket();
    const lightItems = basket.items.map((item) => ({
      article: {
        abbreviation: item.article!.abbreviation,
        id: item.article!.id,
      },
      count: item.count,
    }));
    const lightBasket = new BasketModel({ items: lightItems } as any);
    const response = await Vue.$service.api.basket.updateBasket(lightBasket);
    if (setLocalStorage) {
      this.setLocalBasket(response);
    }
    return response;
  },
  setStorage(key: string, value: any) {
    let dto = value;
    if (dto && typeof (dto as BaseModel<any>).getDTO === 'function') {
      dto = (dto as BaseModel<any>).getDTO();
    }
    Vue.$sessionStorage.set(key, dto);
  },
  getStorage<T>(key: string, modelType?: new(dto: any) => T): T | null {
    const dto = Vue.$sessionStorage.get(key) as any;
    if (dto === null || dto === undefined) {
      return dto;
    }
    if (modelType) {
      // eslint-disable-next-line new-cap
      return new modelType(dto || {} as any);
    }
    return dto;
  },
  setAccount(account: UserAccountModelModel | UserAccountDTO) {
    this.setStorage('account', account);
  },
  getAccount() {
    return this.getStorage('account', UserAccountModelModel);
  },
  setIsMember(isMember: boolean) {
    this.setStorage('isMember', isMember);
  },
  getIsMember() {
    return this.getStorage<boolean>('isMember');
  },
  setWantsToBecomeMember(wantsToBecomeAMember: boolean) {
    this.setStorage('wantsToBecomeAMember', wantsToBecomeAMember);
    this.setIsMember(wantsToBecomeAMember);
  },
  getWantsToBecomeMember() {
    return this.getStorage<boolean>('wantsToBecomeAMember');
  },
  setShippingAddress(value: AddressModel | AddressResourceDTO) {
    this.setStorage('addressShipping', value);
  },
  getShippingAddress() {
    return this.getStorage('addressShipping', AddressModel);
  },
  setInvoiceAddress(value: AddressModel | AddressResourceDTO) {
    this.setStorage('addressInvoice', value);
  },
  getInvoiceAddress() {
    return this.getStorage('addressInvoice', AddressModel);
  },
  setIsShippingSameAsInvoice(value: boolean) {
    this.setStorage('isShippingSameAsInvoice', value);
  },
  getIsShippingSameAsInvoice() {
    return this.getStorage<boolean>('isShippingSameAsInvoice');
  },
  setClientRemark(value: string) {
    this.setStorage('clientRemark', value);
  },
  getClientRemark() {
    return this.getStorage<string | undefined>('clientRemark');
  },
  async sendOrder() {
    await this.updateBasketFromServer();
    const { id } = this.getAccount()!;
    const articles = this.getLocalBasket().items;
    const isMember: boolean = this.getIsMember() ?? false;
    const wantsToBecomeAMember: boolean = this.getWantsToBecomeMember() ?? false;
    const addressInvoice = this.getInvoiceAddress();
    let addressShipping = this.getShippingAddress();
    const isShippingSameAsInvoice = this.getIsShippingSameAsInvoice();
    if (isShippingSameAsInvoice) {
      addressShipping = addressInvoice;
    }
    const addresses = [{ ...addressInvoice, addressType: 0 }, { ...addressShipping, addressType: 1 }];
    const clientRemark = this.getClientRemark();
    const order = {
      userId: id?.toString(),
      wantsToBecomeAMember,
      correspondenceLanguage: Vue.$translation.get(),
      priceCategory: isMember ? 'member' : 'standard',
      clientRemark,
      basket: {
        isShippingSameAsInvoice,
        addresses,
        items: articles,
      },
    };
    const responsePostOrder = await Vue.$service.api.orders.postOrder(new OrderModel(order as any));
    this.setLastOrderNumber(responsePostOrder);
  },
});
