export default {

  loading: 'Loading',
  yes: 'Yes',
  no: 'No',

  category: {
    notFound: 'Category not found',
  },

  sex: {
    sex: 'Gender',
    woman: 'Woman',
    man: 'Man',
  },

  acceptConditions: 'I accept the terms and conditions',

  order: {
    tooHeavy: 'Your order will be delivered by freight carrier',
    thankyou: 'Thank you for your purchase',
    hasBeenRegistered: 'The order has been registered and will be processed soon',
    yourOrderNumber: 'Your order number is:',
    isBeingSent: 'Your order is being registered, this may take a moment',
    clientRemark: 'Additional information',
  },

  login: {
    email: 'E-mail address',
    password: 'Password',
    usernameOrPassword: 'Username/E-Mail',
    registerDescription: 'Please enter your email address.<br> You will then be forwarded to the registration.',
    iAmAlreadyRegistered: 'I am already registered',
    iAmNew: 'I am a new customer',
    loginAction: 'Login',
    requestNewPassword: 'Request new password',
    requestNewPasswordLink: 'https://extern.swissmechanic.ch/en',
    requestNewPasswordAction: 'Request',
    register: 'Register',
    registration: 'Registration',
    accountAlreadyTaken: 'This account already exists, please login',
    invalidLogin: 'Invalid login data',
    passwordResetTitle: 'Forgot your password?',
    passwordResetDescription: 'Enter your username or email.<br>We will send you your new password immediately by email.',
    passwordResetSuccess: 'New password',
    registerSuccess: 'Successfully registered',
    passwordResetSuccessDescription: 'Your password has been reset, you will soon receive an email with the new access data.<br> You will now be forwarded to the login screen.',
    registerSuccessDescription: 'You have been successfully registered... the process will continue soon',
  },

  inputErrors: {
    generic: 'Invalid input',
    telephone: 'Invalid phone number',
    email: 'Invalid email',
  },

  price: {
    standard: 'Non-Member',
    member: 'Member',
  },

  article: {
    articles: 'Articles',
    articleNumber: 'Article number',
    abbreviation: 'Article number',
    addToBasket: 'Add to basket',
    viewDetails: 'View details',
    notAvailable: 'This item is not available',
    loading: 'Loading',
  },

  shoppingBasket: {
    checkoutConfirm: 'Send order',
    checkout: 'Checkout',
    subtotal: 'Subtotal',
    total: 'Total',
    shippingCost: 'Shipping cost',
    vat: 'Vat',
    youSave: 'You save',
    youCouldSave: 'With a membership you could save',
    membershipInformation: 'Information about Memberships',
    membershipPrompt: 'Please enter your member status.',
    areYouAMember: 'Are you already a member?',
    iWantToBeAMember: 'I want to become a member',
    continueShopping: 'Continue shopping',
    shoppingBasket: 'Shopping basket',
    noArticles: 'No articles',
    isTheShippingAddressTheSame: 'Is the shipping address the same?',
    vatSummary: '{vatRate}% of {netAmount}: {vatAmount}',
    remarkForDeliveriesAbroad: '*For shipping to foreign countries, additional taxes or costs (e.g. customs duties) may apply as part of your order.',
  },

  address: {
    addressInvoice: 'Invoice address',
    addressShipping: 'Shipping address',
    address: 'Address',
    company: 'Company',
    firstname: 'First name',
    lastname: 'Last name',
    street: 'Street',
    poBox: 'PO box',
    postcode: 'Postcode',
    city: 'City',
    telephone: 'Telephone',
    fax: 'Fax',
    email: 'Email',
    emailOrderRecipient: 'Email (Recipient of the order confirmation)',
  },

  toolbar: {
    continue: 'Continue',
    back: 'Back',
    search: 'Search',
    searchResults: 'Search results',
  },

  error: {
    displayMore: 'Display more',
    displayLess: 'Display less',
    generic: {
      noMessage: 'No error message was given',
      noDetailMessage: 'No further information is available',
      unknown: 'An unknown error has occurred',
      client: 'An error has occurend on the client',
      server: 'An error has occured on the server',
    },
    network: {
      offline: 'There is no internet connection',
      noServerConnection: 'Connection could not be established to server',
      unknown: 'There was a problem while connecting to the server',
      noResponse: 'No response from the server',
      responseMalformed: 'The data that was received could not be parsed',
      noResultObject: 'No proper response from the server',
      server: 'The server encountered an error and the request could not be completed',
      requestChainFailed: 'A step in the request chain did not complete properly',
      unauthenticated: 'The request could not be authenticated, please log in and try again',
      unauthorized: 'You are not authorized to access this resource',
      badRequest: 'The request was faulty',
      notFound: 'The requested resource could not be found',
      responseNotJSON: 'The response was not in JSON format',
    },
  },
};
