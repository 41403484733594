import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import UserAccountModelDTO from '../dto/resource/UserAccountDTO';
import AddressModel from './AddressModel';

export default class UserAccountModelModel extends BaseModel<UserAccountModelDTO> {
  protected transformToDTO = () => {};

  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }// ?: number;
  set id(value) { this.dto.id = value; }

  /**
   * @type {int64}
   */
  get creatorId() { return this.dto.creatorId; }// ?: number;
  set creatorId(value) { this.dto.creatorId = value; }

  /**
   * @type {date-time}
   */
  get created() { return this.dto.created; }// ?: string;
  set created(value) { this.dto.created = value; }

  /**
   * @type {int64}
   */
  get modifierId() { return this.dto.modifierId; }// ?: null | number;
  set modifierId(value) { this.dto.modifierId = value; }

  /**
   * @type {date-time}
   */
  get modified() { return this.dto.modified; }// ?: null | string;
  set modified(value) { this.dto.modified = value; }

  /**
   */
  get username() { return this.dto.username; }// ?: null | string;
  set username(value) { this.dto.username = value; }

  /**
   */
  get addresses() { return this.getArrayModels(() => this.dto.addresses, AddressModel); }// ?: null | Address[];
  set addresses(value) { this.setArrayModels(() => this.dto.addresses, value!); }

  schema = {
    ...queryBuilder.properties({
      // TODO: Add validation for UserAccountModel
    }),
  } as IJSONSchema;
}
