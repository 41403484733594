import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import CategoryParentReferenceDTO from '../dto/resource/CategoryParentReferenceResourceDTO';

export default class CategoryParentReferenceModel extends BaseModel<CategoryParentReferenceDTO> {
  protected transformToDTO = () => {};

  /**
  */
  get id() { return this.dto.id; } // ?: null | string;
  set id(value) { this.dto.id = value; }

  /**
   */
  get title() { return this.dto.title; } // ?: null | string;
  set title(value) { this.dto.title = value; }

  schema = {
    ...queryBuilder.properties({
      // TODO: Add validation for CategoryParentReference
    }),
  } as IJSONSchema;
}
