export default {

  loading: 'Wird geladen',
  yes: 'Ja',
  no: 'Nein',

  category: {
    notFound: 'Kategorie nicht gefunden',
  },

  sex: {
    sex: 'Geschlecht',
    woman: 'Weiblich',
    man: 'Männlich',
  },

  acceptConditions: 'Ich akzeptiere die allgemeinen Geschäftsbedingungen',

  order: {
    tooHeavy: 'Ihre Bestellung wird per Spedition ausgeliefert',
    thankyou: 'Danke für Ihre Bestellung',
    hasBeenRegistered: 'Ihre Bestellung wurde erfasst und wird demnächst verarbeitet',
    yourOrderNumber: 'Ihre Bestellnummer ist:',
    isBeingSent: 'Ihre Bestellung wird erfasst, dies kann einen Moment dauern',
    clientRemark: 'Zusätzliche Angaben',
  },

  login: {
    email: 'E-Mail-Adresse',
    password: 'Passwort',
    usernameOrPassword: 'Benutzername/E-Mail',
    registerDescription: 'Bitte geben Sie Ihre Email-Adresse ein.<br>Sie werden anschliesend zur Registrierung weitergeleitet.',
    iAmAlreadyRegistered: 'Ich bin bereits registriert',
    iAmNew: 'Ich bin Neukunde',
    loginAction: 'Anmelden',
    requestNewPassword: 'Neues Passwort anfragen',
    requestNewPasswordLink: 'https://extern.swissmechanic.ch/de',
    requestNewPasswordAction: 'Anfordern',
    register: 'Registrieren',
    registration: 'Registrierung',
    accountAlreadyTaken: 'Dieses Konto existiert bereits, bitte anmelden',
    invalidLogin: 'Ungültige Login-Daten',
    passwordResetTitle: 'Passwort vergessen?',
    passwordResetDescription: 'Geben Sie Ihren Benutzernamen oder Email an.<br>Wir senden Ihnen Ihr neues Passwort umgehend per E-Mail.',
    passwordResetSuccess: 'Neues Passwort',
    registerSuccess: 'Erfolgreich registriert',
    passwordResetSuccessDescription: 'Ihr Passwort wurde zurückgesetzt, Sie erhalten demnächst ein Email mit den neuen Zugangsdaten.<br>Sie werden jetzt auf den Loginscreen weitergeleitet.',
    registerSuccessDescription: 'Sie sind erfolgreich registriert worden und werden im Prozess weitergeleitet.',
  },

  inputErrors: {
    generic: 'Ungültige Eingabe',
    telephone: 'Ungültige Telefonnummer',
    email: 'Ungültige Emailadresse',
  },

  price: {
    standard: 'Nichtmitglied',
    member: 'Mitglied',
  },

  article: {
    articles: 'Artikel',
    articleNumber: 'Artikelnummer',
    abbreviation: 'Artikelnummer',
    addToBasket: 'In den Warenkorb',
    viewDetails: 'Details ansehen',
    notAvailable: 'Dieser Artikel ist nicht verfügbar',
    loading: '...lädt...',
  },

  shoppingBasket: {
    checkoutConfirm: 'Bestellung abschicken',
    subtotal: 'Subtotal',
    total: 'Total',
    shippingCost: 'Lieferkosten',
    vat: 'MwSt.',
    youSave: 'Sie sparen',
    youCouldSave: 'Mit einer Mitgliedschaft sparen Sie',
    membershipInformation: 'Informationen zur Mitgliedschaft',
    membershipPrompt: 'Bitte Mitgliedsstatus angeben.',
    areYouAMember: 'Sind Sie bereits Mitglied?',
    iWantToBeAMember: 'Ich will Mitglieder werden',
    continueShopping: 'Weiter einkaufen',
    shoppingBasket: 'Warenkorb',
    noArticles: 'Der Warenkorb ist leer',
    isTheShippingAddressTheSame: 'Ist die Lieferadresse dieselbe?',
    vatSummary: '{vatRate}% von {netAmount}: {vatAmount}',
    remarkForDeliveriesAbroad: '*Bei einem Versand in das Ausland können im Rahmen Ihrer Bestellung zusätzliche Steuern oder Kosten (z. B. Zölle) anfallen.',
  },

  address: {
    addressInvoice: 'Rechnungsadresse',
    addressShipping: 'Lieferadresse',
    address: 'Adresse',
    company: 'Firma',
    firstname: 'Vorname',
    lastname: 'Name',
    street: 'Strasse',
    poBox: 'Postfach',
    postcode: 'PLZ',
    city: 'Ort',
    telephone: 'Telefon',
    fax: 'Telefax',
    email: 'E-Mail',
    emailOrderRecipient: 'E-Mail (Empfänger der Bestellbestätigung)',
  },

  toolbar: {
    continue: 'Weiter',
    back: 'Zurück',
    search: 'Suche',
    searchResults: 'Suchresultate',
  },

  error: {
    displayMore: 'Mehr anzeigen',
    displayLess: 'Weniger anzeigen',
    generic: {
      noMessage: 'Kein Fehlerbeschrieb vorhanden',
      noDetailMessage: 'Keine weitere Informationen vorhanden',
      unknown: 'Ein unbekannter Fehler ist aufgetreten',
      client: 'Ein Fehler ist im Browser aufgetreten',
      server: 'Ein Fehler ist auf dem Server aufgetreten',
    },
    network: {
      offline: 'Kein Verbindung mit dem Internet vorhanden',
      noServerConnection: 'Es konnte keine Verbindung mit dem Server hergestellt werden',
      unknown: 'Ein Fehler ist während der Abfrage aufgetreten',
      noResponse: 'Keine Antwort vom Server',
      responseMalformed: 'Die Daten konnten nicht verarbeitet werden',
      noResultObject: 'Keine korrekte Antwort vom Server',
      server: 'Es ist ein Fehler auf dem Server aufgetreten und die Anfrage konnte nicht verarbeitet werden',
      requestChainFailed: 'Eine Abhängigkeit in der Abfrage wurde nicht erfüllt',
      unauthenticated: 'Die Anfrage konnte nicht authentifiziert werden, bitte melden Sie sich nochmals an',
      unauthorized: 'Sie sind nicht authorisiert auf diese Resource zuzugreifen',
      badRequest: 'Die Anfrage ist fehlerhaft gewesen',
      notFound: 'Die gewünschte Resource konnte nicht gefunden werden',
      responseNotJSON: 'Die Antwort ist nicht im JSON Format',
    },
  },
};
