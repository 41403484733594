import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import Vue from 'vue';
import _ from '@glittr/frontend-core/src/utils';
import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
import CategoryListModel from '../types/model/CategoryListModel';
import CategoryListResourceDTO from '../types/dto/resource/CategoryListResourceDTO';

export class CategoryNotFoundError extends Error {
  constructor() {
    super(Vue.$t('category.notFound'));
    this.name = 'CategoryNotFoundError';
  }
}

export default (service: Servicelayer) => ({
  async getCategory(id: number | string): Promise<CategoryListModel> {
    const response = await this.getCategories();
    if (!id) {
      return response;
    }
    // Recursion
    const queue = [] as CategoryListModel[];
    queue.push(response);
    while (queue.length > 0) {
      const currentCategory = queue.pop()!;
      if (currentCategory.id === id) {
        return currentCategory;
      }
      if (currentCategory.categories) {
        const nextCategories = currentCategory.categories as any;
        queue.push(...nextCategories);
      }
    }
    throw new CategoryNotFoundError();
  },
  async getCategories() {
    const response = await service.get<ServiceResponse<CategoryListResourceDTO>>('Categories/');
    const dto = response.data.result;
    return new CategoryListModel(dto);
  },
});
