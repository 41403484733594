import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import AddressDTO from '../dto/resource/AddressResourceDTO';

export default class AddressModel extends BaseModel<AddressDTO> {
  protected transformToDTO = () => {};

  /**
  * @type {int32}
  */
  get addressType() { return this.dto.addressType; }
  set addressType(value) { this.dto.addressType = value; }

  /**
   */
  get city() { return this.dto.city; }
  set city(value) { this.dto.city = value; }

  /**
   */
  get company() { return this.dto.company; }
  set company(value) { this.dto.company = value; }

  /**
   */
  get email() { return this.dto.email; }
  set email(value) { this.dto.email = value; }

  /**
   */
  get fax() { return this.dto.fax; }
  set fax(value) { this.dto.fax = value; }

  /**
   */
  get firstname() { return this.dto.firstname; }
  set firstname(value) { this.dto.firstname = value; }

  /**
   */
  get lastname() { return this.dto.lastname; }
  set lastname(value) { this.dto.lastname = value; }

  /**
   */
  get sex() { return this.dto.sex; }
  set sex(value) { this.dto.sex = value; }

  /**
   */
  get poBox() { return this.dto.poBox; }
  set poBox(value) { this.dto.poBox = value; }

  /**
   */
  get postCode() { return this.dto.postCode; }
  set postCode(value) { this.dto.postCode = value; }

  /**
   */
  get street() { return this.dto.street; }
  set street(value) { this.dto.street = value; }

  /**
   */
  get telephone() { return this.dto.telephone; }
  set telephone(value) { this.dto.telephone = value; }

  schema = {
    ...queryBuilder.properties({
      email: { required: true, pattern: validationPatterns.email },
      firstname: { required: true },
      lastname: { required: true },
      postCode: { required: true },
      city: { required: true },
      street: { required: true },
    }),
  } as IJSONSchema;
}
