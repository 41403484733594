import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ArticleVariantDTO from '../dto/resource/ArticleVariantResourceDTO';
import ArticleModel from './ArticleModel';

export default class ArticleVariantModel extends BaseModel<ArticleVariantDTO> {
  protected transformToDTO = () => {};

  /**
  */
  get title() { return this.dto.title; }
  set title(value) { this.dto.title = value; }

  /**
   */
  get article() { return this.getObjectModel(() => this.dto.article, ArticleModel); }
  set article(value) { this.setObjectModel(() => this.dto.article, value!); }

  schema = {
    ...queryBuilder.properties({
      // TODO: Add validation for ArticleVariant
    }),
  } as IJSONSchema;
}
