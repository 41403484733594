import Vue, { VueConstructor } from 'vue';
import { CoreServiceAPI } from '@glittr/frontend-core/src/services';
import articleServices from './api/articles';
import basketServices from './api/basket';
import categoryServices from './api/categories';
import orderServices from './api/orders';
import userAccountServices from './api/userAccount';

const loadApi = () => {
  return {
    articles: articleServices(Vue.$service),
    basket: basketServices(Vue.$service),
    categories: categoryServices(Vue.$service),
    orders: orderServices(Vue.$service),
    userAccount: userAccountServices(Vue.$service),
  };
};

export default {
  install: (VuePlugin: VueConstructor, options: any) => {
    VuePlugin.$config.promise.then(() => {
      const coreApi = VuePlugin.$service.api ?? {} as typeof VuePlugin.$service.api;
      VuePlugin.$service.api = { ...coreApi, ...loadApi() };
    });
  },
};

type AppServiceType = ReturnType<typeof loadApi>;
declare module '@glittr/frontend-core/src/plugins/servicelayer/servicelayer' {
  interface ServiceAPI extends AppServiceType, CoreServiceAPI { }
}
