import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import Vue from 'vue';
import _ from '@glittr/frontend-core/src/utils';
import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
import UserAccountModel from '../types/model/UserAccountModel';
import AddressModel from '../types/model/AddressModel';

export default (service: Servicelayer) => ({
  async getIsUsernameAvailable(username: string) {
    const response = await service.get<ServiceResponse<boolean>>(`UserAccount/IsUsernameAvailable/${username}`);
    const dto = response.data.result;
    return dto;
  },
  async postLogin(username: string, password: string) {
    const response = await service.post<ServiceResponse<any>>('UserAccount/Login', { body: { username, password } });
    const dto = response.data.result;
    return new UserAccountModel(dto);
  },
  async postLogout() {
    const response = await service.post<ServiceResponse<any>>('UserAccount/Logout');
    return response;
  },
  async postResetPassword(username: string) {
    const response = await service.post<ServiceResponse<any>>('UserAccount/ResetPassword', { body: { username } });
    return response;
  },
  async postRegister(userData: AddressModel) {
    const response = await service.post<ServiceResponse<any>>('UserAccount/Register', { body: userData.getDTO() });
    const dto = response.data.result;
    return new UserAccountModel(dto);
  },
  // get(username: string) {
  //   return service.get(`UserAccount/${username}`);
  // },
});
