export default {

  loading: 'Chargement',
  yes: 'Oui',
  no: 'Non',

  category: {
    notFound: 'Catégorie non trouvée',
  },

  sex: {
    sex: 'Genre',
    woman: 'Femme',
    man: 'Hommes',
  },

  acceptConditions: "J'accepte les termes et conditions",

  order: {
    tooHeavy: 'Votre commande vous sera livrée par une entreprise de transport',
    thankyou: 'Merci pour votre achat',
    hasBeenRegistered: 'La commande a été enregistrée et sera traitée bientôt',
    yourOrderNumber: 'Votre numéro de commande est:',
    isBeingSent: 'Votre commande est en cours de traitement, cela peut prendre un moment',
    clientRemark: 'Informations complémentaires',
  },

  login: {
    email: 'Adresse E-mail',
    password: 'Mot de passe',
    usernameOrPassword: "Nom d'utilisateur/E-Mail",
    registerDescription: "Veuillez entrer votre adresse email.<br> Vous serez alors redirigé vers l'inscription.",
    iAmAlreadyRegistered: 'Je suis déjà inscrit',
    iAmNew: 'Je suis un nouveau client',
    loginAction: 'Connexion',
    requestNewPassword: 'Demander un nouveau mot de passe',
    requestNewPasswordLink: 'https://extern.swissmechanic.ch/fr',
    requestNewPasswordAction: 'Demande',
    register: "S'inscrire",
    registration: 'Inscription',
    accountAlreadyTaken: 'Ce compte existe déjà, veuillez vous connecter',
    invalidLogin: 'Données de connexion non valides',
    passwordResetTitle: 'Mot de passe oublié ?',
    passwordResetDescription: "Entrez votre nom d'utilisateur ou votre courriel à .<br>Nous vous enverrons immédiatement votre nouveau mot de passe par courriel.",
    passwordResetSuccess: 'Nouveau mot de passe',
    registerSuccess: 'Enregistré avec succès',
    passwordResetSuccessDescription: "Votre mot de passe a été réinitialisé, vous recevrez bientôt un email avec les nouvelles données d'accès.<br> Vous serez maintenant redirigé vers l'écran de connexion.",
    registerSuccessDescription: 'Vous avez été enregistré avec succès et vous serez transmis dans le processus.',
  },

  inputErrors: {
    generic: 'Entrée invalide',
    telephone: 'Numéro de téléphone invalide',
    email: 'Email invalide',
  },

  price: {
    standard: 'Non-Membre',
    member: 'Membre',
  },

  article: {
    articles: 'Articles',
    articleNumber: "Numéro de l'article",
    abbreviation: "Numéro de l'article",
    addToBasket: 'Ajouter au panier',
    viewDetails: 'Voir les détails',
    notAvailable: "Cet article n'est pas disponible",
    loading: 'En cours de chargement',
  },

  shoppingBasket: {
    checkoutConfirm: "Envoyer l'ordre",
    checkout: 'Commander',
    subtotal: 'Sous-total',
    total: 'Total',
    shippingCost: 'Frais de port',
    vat: 'TVA',
    youSave: 'Vous enregistrez',
    youCouldSave: 'Avec une adhésion, vous pourriez économiser',
    membershipInformation: 'Informations sur une adhésion',
    membershipPrompt: 'Veuillez entrer votre statut de membre.',
    areYouAMember: 'Êtes-vous déjà membre?',
    iWantToBeAMember: 'Je veux devenir membre',
    continueShopping: 'Continue shopping',
    shoppingBasket: 'Panier',
    noArticles: 'Votre panier est encore vide',
    isTheShippingAddressTheSame: "L'adresse de livraison est-elle la même?",
    vatSummary: '{vatRate}% de {netAmount}: {vatAmount}',
    remarkForDeliveriesAbroad: "*En cas d'envoi à l'étranger, des taxes ou des frais supplémentaires (par exemple des droits de douane) peuvent être appliqués dans le cadre de votre commande.",
  },

  address: {
    addressInvoice: 'Adresse de facturation',
    addressShipping: 'Adresse de livraison',
    address: 'Adresse',
    company: 'Entreprise',
    firstname: 'Prénom',
    lastname: 'Nom de famille',
    street: 'Rue',
    poBox: 'Boîte postale',
    postcode: 'Code postal',
    city: 'Ville',
    telephone: 'Téléphone',
    fax: 'Fax',
    email: 'Email',
    emailOrderRecipient: 'E-mail (destinataire de la confirmation de la commande)',
  },

  toolbar: {
    continue: 'Continuer',
    back: 'Retour',
    search: 'Chercher',
    searchResults: 'Chercher',
  },

  error: {
    displayMore: 'Afficher plus',
    displayLess: 'Afficher moins',
    generic: {
      noMessage: "Pas de description d'erreur disponible",
      noDetailMessage: 'Aucune information supplémentaire disponible',
      unknown: 'Une erreur inconnue est survenue',
      client: 'Une erreur est survenue dans le navigateur.',
      server: 'Une erreur est survenue sur le serveur',
    },
    network: {
      offline: 'Pas de connexion à Internet disponible',
      noServerConnection: 'Impossible de se connecter au serveur',
      unknown: 'Une erreur est survenue pendant la requête',
      noResponse: 'Pas de réponse du serveur',
      responseMalformed: "Les données n'ont pas pu être traitées",
      noResultObject: 'Pas de réponse correcte du serveur',
      server: "Une erreur s'est produite sur le serveur et la demande n'a pas pu être traitée.",
      requestChainFailed: "Une dépendance dans la requête n'a pas été remplie",
      unauthenticated: "La demande n'a pas pu être authentifiée, veuillez vous reconnecter.",
      unauthorized: "Vous n'êtes pas autorisé à accéder à cette ressource",
      badRequest: 'La demande était incorrecte',
      notFound: 'La ressource souhaitée est introuvable',
      responseNotJSON: "La réponse n'est pas au format JSON",
    },
  },
};
