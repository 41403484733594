import { CoreOptions } from '@glittr/frontend-core';
import { VueConstructor } from 'vue';
import ShoppingBasket from './shoppingBasket';

declare module 'vue/types/vue' {
  interface Vue {
    $shoppingBasket: typeof ShoppingBasket
  }
  interface VueConstructor {
    $shoppingBasket: typeof ShoppingBasket
  }
}

export default {
  install: (Vue: VueConstructor, options: CoreOptions) => {
    Vue.$shoppingBasket = ShoppingBasket;
    Vue.prototype.$shoppingBasket = Vue.$shoppingBasket;
  },
};
