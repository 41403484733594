import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import Vue from 'vue';
import _ from '@glittr/frontend-core/src/utils';
import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
import BasketModel from '../types/model/BasketModel';
import BasketResourceDTO from '../types/dto/resource/BasketResourceDTO';

export default (service: Servicelayer) => ({
  async updateBasket(basket: BasketModel) {
    const response = await service.post<ServiceResponse<BasketResourceDTO>>('Basket/', { body: basket.getDTO() });
    const dto = response.data.result;
    return new BasketModel(dto);
  },
});
