import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import CategoryListDTO from '../dto/resource/CategoryListResourceDTO';
import CategoryParentReferenceModel from './CategoryParentReferenceModel';

export default class CategoryListModel extends BaseModel<CategoryListDTO> {
  protected transformToDTO = () => {};

  /**
  */
  get id() { return this.dto.id; } // ?: null | string;
  set id(value) { this.dto.id = value; }

  /**
   */
  get title() { return this.dto.title; } // ?: null | string;
  set title(value) { this.dto.title = value; }

  /**
   */
  get categories() { return this.getArrayModels(() => this.dto.categories, CategoryListModel); } // ?: null | CategoryListResourceDTO[];
  set categories(value) { this.setArrayModels(() => this.dto.categories, value!); }

  /**
   */
  get articles() { return this.dto.articles; } // ?: null | string[];
  set articles(value) { this.dto.articles = value; }

  /**
   */
  get parent() { return this.getObjectModel(() => this.dto.parent, CategoryParentReferenceModel); } // ?: CategoryParentReferenceResource;
  set parent(value) { this.setObjectModel(() => this.dto.parent, value!); }

  /**
   */
  get breadcrumb() { return this.getArrayModels(() => this.dto.breadcrumb, CategoryParentReferenceModel); } // ?: null | CategoryParentReferenceResource[];
  set breadcrumb(value) { this.setArrayModels(() => this.dto.breadcrumb, value!); }

  schema = {
    ...queryBuilder.properties({
      // TODO: Add validation for CategoryList
    }),
  } as IJSONSchema;
}
