import Vue from 'vue';
import { Route, NavigationGuardNext } from 'vue-router';

export default {
  // TODO: Due to a bug in glittr.core and time-constraints the bug has been fixed locally in this project
  // When the bugs #3446, #3448 have been resolved in glittr.core use the core translation guard instead
  routeBeforeEnter(to: Route, from: Route, next: NavigationGuardNext) {
    if (to.query) {
      const languageQuery = to.query.language;
      if (languageQuery) {
        Vue.$translation.set(languageQuery as string);
      }
    }
    next();
  },
};
