import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import BasketItemDTO from '../dto/resource/BasketItemResourceDTO';
import ArticleModel from './ArticleModel';

export default class BasketItemModel extends BaseModel<BasketItemDTO> {
  protected transformToDTO = () => {};

  /**
  * @type {int32}
  */
  get count() { return this.dto.count; } // ?: number;
  set count(value) { this.dto.count = value; }

  /**
   */
  get article() { return this.getObjectModel(() => this.dto.article, ArticleModel); } // ?: ArticleResource;
  set article(value) { this.setObjectModel(() => this.dto.article, value!); }

  schema = {
    ...queryBuilder.properties({
      // TODO: Add validation for BasketItem
    }),
  } as IJSONSchema;
}
