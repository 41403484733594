import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import Vue from 'vue';
import _ from '@glittr/frontend-core/src/utils';
import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
import ListDTO from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/listDTO';
import ListModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/listModel';
import GetArticlesRequest from '../types/dto/request/GetArticlesRequest';
import GetArticleRequest from '../types/dto/request/GetArticleRequest';
import ArticleModel from '../types/model/ArticleModel';
import ArticleResourceDTO from '../types/dto/resource/ArticleResourceDTO';

export default (service: Servicelayer) => ({
  warmArticles() {
    // TODO: Backend call
  },
  getArticlePreviewImage(article: ArticleModel) {
    if (_.isNil(article)) {
      return '';
    }
    if (_.isEmpty(article.imagePreviewId)) {
      return '';
    }
    let url = Vue.$axios.defaults.baseURL;
    url += `Articles/${article.id}/PreviewImage`;
    return url;
  },
  getArticleDetailImage(article: ArticleModel) {
    if (_.isNil(article)) {
      return '';
    }
    if (_.isEmpty(article.imageDetailId)) {
      return '';
    }
    let url = Vue.$axios.defaults.baseURL;
    url += `Articles/${article.id}/DetailImage`;
    return url;
  },
  async getArticleByAbbreviation(abbreviation: string) {
    const response = await service.get(`Articles/ByAbbreviation/${abbreviation}`);
    const dto = response.data.result;
    return new ArticleModel(dto);
  },
  async getArticle(id: string | number) {
    const response = await service.get(`Articles/${id}`);
    const dto = response.data.result;
    return new ArticleModel(dto);
  },
  async getArticles(query: GetArticlesRequest) {
    // const categoryId = query.searchTerm ? undefined : query.categoryId;
    const language = query.language ?? Vue.$translation.get();
    const response = await service.get<ServiceResponse<ListDTO<ArticleResourceDTO>>>('Articles', { query: { ...query, language } });
    const dto = response.data.result;
    return new ListModel(dto, ArticleModel);
  },
});
