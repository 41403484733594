export default {

  loading: 'Caricamento',
  yes: 'Sì',
  no: 'No',

  category: {
    notFound: 'Categoria non trovata',
  },

  sex: {
    sex: 'Sesso',
    woman: 'Femminile',
    man: 'Maschio',
  },

  acceptConditions: 'Accetto i Termini e le Condizioni',

  order: {
    tooHeavy: 'La vostra ordinazione verrà recapitata tramite spedizioniere',
    thankyou: 'Grazie per il vostro acquisto',
    hasBeenRegistered: "L'ordine è stato registrato e verrà elaborato presto",
    yourOrderNumber: "Il numero dell'ordine è:",
    isBeingSent: 'Il tuo ordine è in fase di elaborazione, questo potrebbe richiedere qualche istante',
    clientRemark: 'Informazioni aggiuntive',
  },

  login: {
    email: 'Indirizzo e-mail',
    password: 'Password',
    usernameOrPassword: 'Nome utente/e-mail',
    registerDescription: 'Inserisci il tuo indirizzo e-mail.<br> Sarai quindi reindirizzato alla registrazione.',
    iAmAlreadyRegistered: 'Sono già registrato',
    iAmNew: 'Sono un nuovo cliente',
    loginAction: 'Login',
    requestNewPassword: 'Richiedi una nuova password',
    requestNewPasswordLink: 'https://extern.swissmechanic.ch/it',
    requestNewPasswordAction: 'Richiesta',
    register: 'Registrati',
    registration: 'Registrazione',
    accountAlreadyTaken: 'Questo account esiste già, effettua il login',
    invalidLogin: 'Dati di accesso non validi',
    passwordResetTitle: 'Hai dimenticato la password?',
    passwordResetDescription: 'Inserisci il tuo nome utente o la tua email a.<br>Ti invieremo immediatamente la tua nuova password via email.',
    passwordResetSuccess: 'Nuova password',
    registerSuccess: 'Registrato con successo',
    passwordResetSuccessDescription: "La tua password è stata reimpostata, riceverai presto un'e-mail con i nuovi dati di accesso.<br> Ora verrai inoltrato alla schermata di login.",
    registerSuccessDescription: "L'utente è stato registrato con successo e verrà inoltrato nel processo.",
  },

  inputErrors: {
    generic: 'Input non valido',
    telephone: 'Numero di telefono invalido',
    email: 'E-mail non valido',
  },

  price: {
    standard: 'Non-membro',
    member: 'Membro',
  },

  article: {
    articles: 'Articoli',
    articleNumber: "Numero dell'articolo",
    abbreviation: "Numero dell'articolo",
    addToBasket: 'Mettere nel paniere',
    viewDetails: 'Dettagli articlolo',
    notAvailable: 'Questo articolo non è disponibile',
    loading: 'Vengono caricati',
  },

  shoppingBasket: {
    checkoutConfirm: 'Invia ordine',
    checkout: 'Ordinare',
    subtotal: 'Totale parziale',
    total: 'Totale',
    shippingCost: 'Costo di consegna',
    vat: 'IVA',
    youSave: 'Risparmi',
    youCouldSave: 'Potresti salvare',
    membershipInformation: 'Informazioni su un abbonamento',
    membershipPrompt: 'Inserisci lo stato del tuo membro.',
    areYouAMember: 'Sei già iscritto?',
    iWantToBeAMember: 'Voglio diventare un membro',
    continueShopping: 'Continue shopping',
    shoppingBasket: 'Paniere',
    noArticles: 'Non avete prodotti nel vostro paniere.',
    isTheShippingAddressTheSame: "L'indirizzo di consegna è lo stesso?",
    vatSummary: '{vatRate}% di {netAmount}: {vatAmount}',
    remarkForDeliveriesAbroad: "*In caso di spedizione all'estero, l'ordine potrebbe essere gravato da tasse o costi aggiuntivi (ad esempio, dazi doganali).",
  },

  address: {
    addressInvoice: 'Indirizzo di fatturazione',
    addressShipping: 'Indirizzo di spedizione',
    address: 'Indirizzo',
    company: 'Azienda',
    firstname: 'Nome',
    lastname: 'Cognome',
    street: 'Strada',
    poBox: 'Boîte postale',
    postcode: 'Casella postale',
    city: 'Città',
    telephone: 'Telefono',
    fax: 'Fax',
    email: 'Email',
    emailOrderRecipient: 'E-mail (destinatario della conferma dell ordine)',
  },

  toolbar: {
    continue: 'Continua',
    back: 'Indietro',
    search: 'Cerca',
    searchResults: 'Cerca',
  },

  error: {
    displayMore: 'Mostra altro',
    displayLess: 'Mostra di meno',
    generic: {
      noMessage: "Nessuna descrizione dell'errore disponibile",
      noDetailMessage: 'Nessuna ulteriore informazione disponibile',
      unknown: 'Si è verificato un errore sconosciuto',
      client: 'Si è verificato un errore nel browser',
      server: 'Si è verificato un errore sul server',
    },
    network: {
      offline: 'Nessuna connessione a Internet disponibile',
      noServerConnection: 'Impossibile connettersi al server',
      unknown: 'Si è verificato un errore durante la query',
      noResponse: 'Nessuna risposta dal server',
      responseMalformed: 'I dati non possono essere elaborati',
      noResultObject: 'Nessuna risposta corretta dal server',
      server: 'Si è verificato un errore sul server e non è stato possibile elaborare la richiesta',
      requestChainFailed: 'Una dipendenza nella query non è stata soddisfatta',
      unauthenticated: 'La richiesta non può essere autenticata, accedi nuovamente',
      unauthorized: 'Non sei autorizzato ad accedere a questa risorsa',
      badRequest: 'La richiesta non era corretta',
      notFound: 'La risorsa desiderata non è stata trovata',
      responseNotJSON: 'La risposta non è in formato JSON',
    },
  },
};
