import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ArticleDTO from '../dto/resource/ArticleResourceDTO';
import PriceModel from './PriceModel';
import ArticleVariantModel from './ArticleVariantModel';

export default class ArticleModel extends BaseModel<ArticleDTO> {
  protected transformToDTO = () => {};

  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }

  /**
   */
  get links() { return this.dto.links; }
  set links(value) { this.dto.links = value; }

  /**
   * @type {int64}
   */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }

  /**
   */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }

  /**
   * @type {date-time}
   */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }

  /**
   * @type {int64}
   */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }

  /**
   */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }

  /**
   * @type {date-time}
   */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }

  /**
   */
  get title() { return this.dto.title; }
  set title(value) { this.dto.title = value; }

  /**
   */
  get description() { return this.dto.description; }
  set description(value) { this.dto.description = value; }

  /**
   */
  get detailDescription() { return this.dto.detailDescription; }
  set detailDescription(value) { this.dto.detailDescription = value; }

  /**
   */
  get articleNumber() { return this.dto.articleNumber; }
  set articleNumber(value) { this.dto.articleNumber = value; }

  /**
   */
  get abbreviation() { return this.dto.abbreviation; }
  set abbreviation(value) { this.dto.abbreviation = value; }

  /**
   */
  get dimensions() { return this.dto.dimensions; }
  set dimensions(value) { this.dto.dimensions = value; }

  /**
   * @type {double}
   */
  get weight() { return this.dto.weight; }
  set weight(value) { this.dto.weight = value; }

  /**
   */
  get prices() { return this.getArrayModels(() => this.dto.prices, PriceModel); }
  set prices(value) { this.setArrayModels(() => this.dto.prices, value!); }

  /**
   */
  get imageDetailId() { return this.dto.imageDetailId; }
  set imageDetailId(value) { this.dto.imageDetailId = value; }

  /**
   */
  get imagePreviewId() { return this.dto.imagePreviewId; }
  set imagePreviewId(value) { this.dto.imagePreviewId = value; }

  /**
   */
  get variants() { return this.getArrayModels(() => this.dto.variants, ArticleVariantModel); }
  set variants(value) { this.setArrayModels(() => this.dto.variants, value!); }

  schema = {
    ...queryBuilder.properties({
      // TODO: Add validation for Article
    }),
  } as IJSONSchema;
}
