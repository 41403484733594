import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import OrderDTO from '../dto/resource/OrderResourceDTO';
import BasketModel from './BasketModel';

export default class OrderModel extends BaseModel<OrderDTO> {
  protected transformToDTO = () => {};

  get correspondenceLanguage() { return this.dto.correspondenceLanguage; }
  set correspondenceLanguage(value) { this.dto.correspondenceLanguage = value; }

  /**
  */
  get userId() { return this.dto.userId; }
  set userId(value) { this.dto.userId = value; }

  /**
  */
  get clientRemark() { return this.dto.clientRemark; }
  set clientRemark(value) { this.dto.clientRemark = value; }

  /**
   */
  get orderNumber() { return this.dto.orderNumber; }
  set orderNumber(value) { this.dto.orderNumber = value; }

  /**
   */
  get wantsToBecomeAMember() { return this.dto.wantsToBecomeAMember; }
  set wantsToBecomeAMember(value) { this.dto.wantsToBecomeAMember = value; }

  /**
   */
  get priceCategory() { return this.dto.priceCategory; }
  set priceCategory(value) { this.dto.priceCategory = value; }

  /**
   */
  get basket() { return this.getObjectModel(() => this.dto.basket, BasketModel); }
  set basket(value) { this.setObjectModel(() => this.dto.basket, value!); }

  schema = {
    ...queryBuilder.properties({
      // TODO: Add validation for Order
    }),
  } as IJSONSchema;
}
