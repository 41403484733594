export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [{
    path: '/article-detail',
    component: () => import('./views/pages/app/article-detail.vue' /* webpackChunkName: "pages_article-detail" */),
    name: 'article-detail',
  }, {
    path: '/article-list',
    component: () => import('./views/pages/app/article-list.vue' /* webpackChunkName: "pages_article-list" */),
    name: 'article-list',
  }, {
    path: '/checkout-address',
    component: () => import('./views/pages/app/checkout-address.vue' /* webpackChunkName: "pages_checkout-address" */),
    name: 'checkout-address',
  }, {
    path: '/checkout-overview',
    component: () => import('./views/pages/app/checkout-overview.vue' /* webpackChunkName: "pages_checkout-overview" */),
    name: 'checkout-overview',
  }, {
    path: '/login',
    component: () => import('./views/pages/app/login.vue' /* webpackChunkName: "pages_login" */),
    name: 'login',
  }, {
    path: '/password-reset',
    component: () => import('./views/pages/app/password-reset.vue' /* webpackChunkName: "pages_password-reset" */),
    name: 'password-reset',
  }, {
    path: '/password-reset-success',
    component: () => import('./views/pages/app/password-reset-success.vue' /* webpackChunkName: "pages_password-reset-success" */),
    name: 'password-reset-success',
  }, {
    path: '/register',
    component: () => import('./views/pages/app/register.vue' /* webpackChunkName: "pages_register" */),
    name: 'register',
  }, {
    path: '/register-success',
    component: () => import('./views/pages/app/register-success.vue' /* webpackChunkName: "pages_register-success" */),
    name: 'register-success',
  }, {
    path: '/shopping-basket',
    component: () => import('./views/pages/app/shopping-basket.vue' /* webpackChunkName: "pages_shopping-basket" */),
    name: 'shopping-basket',
  }, {
    path: '/thankyou',
    component: () => import('./views/pages/app/thankyou.vue' /* webpackChunkName: "pages_thankyou" */),
    name: 'thankyou',
  }, {
    path: '/',
    component: () => import('./views/pages/app/index.vue' /* webpackChunkName: "pages_index" */),
    name: 'index',
  }, {
    path: '*',
    component: () => import('./views/pages/app/index.vue' /* webpackChunkName: "pages_index" */),
    name: 'custom',
  }],
};
