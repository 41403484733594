import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ShippingInfoDTO from '../dto/resource/ShippingInfoResourceDTO';

export default class ShippingInfoModel extends BaseModel<ShippingInfoDTO> {
  protected transformToDTO = () => {};
  get shippingAmount() { return this.dto.shippingAmount; }// : number,
  set shippingAmount(value) { this.dto.shippingAmount = value; }
  get shippingMethod() { return this.dto.shippingMethod; }// : string | null,
  set shippingMethod(value) { this.dto.shippingMethod = value; }
  get isShippingAmountCalculated() { return this.dto.isShippingAmountCalculated; }// : boolean,
  set isShippingAmountCalculated(value) { this.dto.isShippingAmountCalculated = value; }
  schema = {
    ...queryBuilder.properties({
      // TODO: Add validation for ShippingInfo
    }),
  } as IJSONSchema;
}
