import de from './translations/legacy/de';
import en from './translations/legacy/en';
import it from './translations/legacy/it';
import fr from './translations/legacy/fr';

// TODO: Structure properly into separate files
// import de from '@src/localization/translations/de/';
// import en from '@src/localization/translations/en/';
// import it from '@src/localization/translations/it/';
// import fr from '@src/localization/translations/fr/';

type TranslationType = typeof de;
declare module '@core/plugins/localization/translator' {
  interface TranslationMap extends TranslationType { }
}

export default {
  translations: {
    de,
    en,
    it,
    fr,
  },
};
