import Vue from 'vue';

const appStore = {
  // Add store values here
  title: '' as string,
};

type AppStoreType = typeof appStore;
declare module '@glittr/frontend-core/src/plugins/store/store' {
  interface StoreValues extends AppStoreType {}
}

Object.keys(appStore).forEach((propName) => {
  Vue.set(Vue.$store.values, propName, (appStore as any)[propName]);
});

export default appStore;
