import Vue from 'vue';
import core, { CoreOptions } from '@glittr/frontend-core';
import App from './app.vue';
import router from './router';
import localization from './localization';
import services from './services';
import shoppingBasket from './plugins/shoppingBasket';
// ______FIELDS
import vNumberField from './components/fields/v-number-field.vue';
import vTelephoneField from './components/fields/v-telephone-field.vue';
import vPostcodeField from './components/fields/v-postcode-field.vue';
import vEmailField from './components/fields/v-email-field.vue';
// ______SHOP
import vShoppingBasketCosts from './components/v-shopping-basket-costs.vue';
import vFormAddress from './components/v-form-address.vue';
import vArticleListActions from './components/v-article-list-actions.vue';
import vArticleBasketActions from './components/v-article-basket-actions.vue';
import vArticleImage from './components/v-article-image.vue';
import vArticleListEntry from './components/v-article-list-entry.vue';
import vArticleList from './components/v-article-list.vue';
import vArticleDetail from './components/v-article-detail.vue';
// ______NAVIGATION
import vLayeredNavigation from './components/v-layered-navigation.vue';

import translationGuard from './guards/translation';

Vue.config.productionTip = false;
const APP_ID = 'app';

// Core entry point
Vue.use(core, {
  appId: APP_ID,
  translations: localization.translations,
  router,
  Vue,
} as CoreOptions);

// APP____________________________________________________________

// STYLES
require('./assets/styles/main.scss');

// PLUGINS
Vue.use(services);
Vue.use(shoppingBasket);

// COMPONENTS
Vue.component('VNumberField', { extends: vNumberField });
Vue.component('VTelephoneField', { extends: vTelephoneField });
Vue.component('VPostcodeField', { extends: vPostcodeField });
Vue.component('VEmailField', { extends: vEmailField });
Vue.component('VShoppingBasketCosts', { extends: vShoppingBasketCosts });
Vue.component('VFormAddress', { extends: vFormAddress });
Vue.component('VArticleListActions', { extends: vArticleListActions });
Vue.component('VArticleBasketActions', { extends: vArticleBasketActions });
Vue.component('VArticleImage', { extends: vArticleImage });
Vue.component('VArticleListEntry', { extends: vArticleListEntry });
Vue.component('VArticleList', { extends: vArticleList });
Vue.component('VArticleDetail', { extends: vArticleDetail });
Vue.component('VLayeredNavigation', { extends: vLayeredNavigation });

// GUARDS
Vue.$router.beforeEach(translationGuard.routeBeforeEnter);

// _______________________________________________________________

const app = new Vue({
  router: Vue.$router,
  render: (h) => h(App),
});

const mountDiv = document.createElement('div');
const appNode = document.getElementById(APP_ID);
if (appNode === null || appNode === undefined) {
  console.error(`Unable to mount app, no node with id '${APP_ID}' was found`);
} else {
  appNode.style.height = '100%';
  const foo = appNode.insertBefore(mountDiv, null);
  app.$mount(mountDiv);
}
require('./store');

declare module '@glittr/frontend-core/src/plugins/config/config' {
  interface ConfigType
  {
    'layout': {
      'embedded': boolean,
    }
  }
}
