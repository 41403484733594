import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import BasketDTO from '../dto/resource/BasketResourceDTO';
import BasketItemModel from './BasketItemModel';
import AddressModel from './AddressModel';
import BasketSummaryModel from './BasketSummaryModel';

export default class BasketModel extends BaseModel<BasketDTO> {
  protected transformToDTO = () => {};

  /**
  * @type {int64}
  */
  get id() { return this.dto.id; } // ?: number;
  set id(value) { this.dto.id = value; }

  /**
   */
  get links() { return this.dto.links; } // ?: null | ResourceLink[];
  set links(value) { this.dto.links = value; }

  /**
   */
  get items() { return this.getArrayModels(() => this.dto.items, BasketItemModel); } // ?: null | BasketItemResource[];
  set items(value) { this.setArrayModels(() => this.dto.items, value!); }

  /**
   */
  get addresses() { return this.getArrayModels(() => this.dto.addresses, AddressModel); } // ?: null | AddressResource[];
  set addresses(value) { this.setArrayModels(() => this.dto.addresses, value!); }

  /**
   */
  get isShippingSameAsInvoice() { return this.dto.isShippingSameAsInvoice; } // ?: boolean;
  set isShippingSameAsInvoice(value) { this.dto.isShippingSameAsInvoice = value; }

  /**
   */
  get summary() { return this.getArrayModels(() => this.dto.summary, BasketSummaryModel); } // ?: null | BasketSummaryResource[];
  set summary(value) { this.setArrayModels(() => this.dto.summary, value!); }

  schema = {
    ...queryBuilder.properties({
      // TODO: Add validation for Basket
    }),
  } as IJSONSchema;
}
