import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import PriceDTO from '../dto/resource/PriceResourceDTO';

export default class PriceModel extends BaseModel<PriceDTO> {
  protected transformToDTO = () => {};

  /**
  */
  get priceType() { return this.dto.priceType; }
  set priceType(value) { this.dto.priceType = value; }

  /**
   * @type {double}
   */
  get netPrice() { return this.dto.netPrice; }
  set netPrice(value) { this.dto.netPrice = value; }

  /**
   * @type {double}
   */
  get vatRate() { return this.dto.vatRate; }
  set vatRate(value) { this.dto.vatRate = value; }

  /**
   * @type {double}
   */
  get vatAmount() { return this.dto.vatAmount; }

  /**
   * @type {double}
   */
  get grossPrice() { return this.dto.grossPrice; }

  schema = {
    ...queryBuilder.properties({
      // TODO: Add validation for Price
    }),
  } as IJSONSchema;
}
