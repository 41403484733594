import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import Vue from 'vue';
import _ from '@glittr/frontend-core/src/utils';
import ServiceResponse from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/serviceResponse';
import OrderModel from '../types/model/OrderModel';

export default (service: Servicelayer) => ({
  async postOrder(order: OrderModel) {
    const response = await service.post<ServiceResponse<number>>('Orders/', { body: order.getDTO() });
    return response.data.result;
  },
});
