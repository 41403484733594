import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import BasketSummaryDTO from '../dto/resource/BasketSummaryResourceDTO';
import ShippingInfoModel from './ShippingInfoModel';

export default class BasketSummaryModel extends BaseModel<BasketSummaryDTO> {
  protected transformToDTO = () => {};

  /**
  */
  get priceType() { return this.dto.priceType; }// ?: null | string;
  set priceType(value) { this.dto.priceType = value; }

  /**
   * @type {double}
   */
  get netAmount() { return this.dto.netAmount; }// ?: number;
  set netAmount(value) { this.dto.netAmount = value; }

  /**
   * @type {double}
   */
  get vatAmount() { return this.dto.vatAmount; }// ?: number;
  set vatAmount(value) { this.dto.vatAmount = value; }

  /**
   * @type {double}
   */
  get grossAmount() { return this.dto.grossAmount; }// ?: number;

  /**
   */
  get shippingInfo() { return this.getObjectModel(() => this.dto.shippingInfo, ShippingInfoModel); }// ?: ShippingInfoResource;
  set shippingInfo(value) { this.setObjectModel(() => this.dto.shippingInfo, value!); }

  /**
   * @type {double}
   */
  get grandTotalAmount() { return this.dto.grandTotalAmount; }// ?: number;

  /**
   * @type {double}
   */
  get totalWeight() { return this.dto.totalWeight; }// ?: number;
  set totalWeight(value) { this.dto.totalWeight = value; }

  /**
   * @type {int32}
   */
  get totalQuantity() { return this.dto.totalQuantity; }// ?: number;
  set totalQuantity(value) { this.dto.totalQuantity = value; }

  get vatSummaryItems() { return this.dto.vatSummaryItems; }

  schema = {
    ...queryBuilder.properties({
      // TODO: Add validation for BasketSummary
    }),
  } as IJSONSchema;
}
